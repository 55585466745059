$purple: #775da6;
$lightBlue: #70b6c1;
$successGreen: #018e42;
$deepBlue: #008ee0; // rgba rgb(0,142,224)
$deepBlueActive: #0067a2;
$alert: #fa5a48;
$alertActive: #cf3928;
$lightGrey: #dcdcdc;
$doubleLightGrey: #F9FAFC;
$darkGrey: #5a5a5a; // rgb(90,90,90)
$black: #202020;