.container {
  padding: 0 90px;

  @media (max-width: 1280px) {
    padding: 0 70px;
  }

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.base-header {
  font-size: 24px;
  margin: 20px 0;
}

.base-button {
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  border: none;
  background: $deepBlue;
  transition: 0.5s;

  &--alert {
    height: 40px;
    padding: 0 10px;
    border-radius: 10px;
    box-sizing: border-box;
    color: white;
    font-size: 18px;
    border: none;
    background: $alert;
    transition: 0.5s;

    &:hover {
      cursor: pointer;
      background: $alertActive;
    }
  }

  &:hover {
    cursor: pointer;
    background: $deepBlueActive;
  }
}

.base-button--disabled {
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  border: none;
  background: $lightGrey;
  transition: 0.5s;
  pointer-events: none;
}

.base-alert-message {
  margin: 5px 0;
  color: $alert;
}

.base-alert-message--hidden {
  margin: 5px 0;
  color: $alert;
  visibility: hidden;
}

.cross-icon {
  &:hover {
    cursor: pointer;
    svg {
      transform: scale(1.2);
    }
  }
}

.base-label {
  border: 1px solid $black;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    outline: 1px solid $darkGrey;
  }

  &:active {
    cursor: pointer;
    outline: 1px solid $darkGrey;
  }

  &--password {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 15px;
  }

  &--active {
    outline: 1px solid $darkGrey;
  }
}

.base-input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  height: 40px;
  padding-left: 10px;
  font-size: 18px;

  &:focus-visible {
    outline: none;
  }
}

.base-wikkeo-logo {
  width: 50px;
  height: 50px;
  margin-right: 25px;
  background: url("https://wikkeo.com/assets/images/16549315851636064358wik004.png");
  background-size: cover;
}

span,
a,
li,
ol,
p,
h2,
h3,
thead,
td,
tr {
  margin: 0;
  font-family: Poppins, sans-serif !important;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.base-shadow {
  -webkit-box-shadow: 1px 1px 8px 0px rgba(34, 34, 34, 0.2);
  -moz-box-shadow: 1px 1px 8px 0px rgba(34, 34, 34, 0.2);
  box-shadow: 1px 1px 8px 0px rgba(34, 34, 34, 0.2);
}

html,
#root {
  height: 100%;
  background-color: white;
}

.alert-request {
  position: fixed;
  width: 350px;
  right: 50px;
  top: 30px;
  z-index: 10000;

  @media (max-width: 500px) { 
    right: 0;
    top: 10px;
    width: 100%;
  }
}

