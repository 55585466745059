.DeleteOrderModal {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.buttons {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}