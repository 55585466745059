.SupportButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  width: 264px;
  height: 48px;
  border-radius: 10px;
  margin: 0 auto 20px auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  a {
    text-decoration: none;
    color: gray;
  }
}

.icon {
  color: gray;
}
