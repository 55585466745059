.create-new-order__form {
  margin-bottom: 50px;
}

.modalCreateOrder {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}